import React from 'react';
import { Row, Col } from 'antd';
import Layout from '../components/Layout/layout';
import SEO from '../components/seo';
import ContactUsHero from '../components/Hero/contact-us-hero';
import { contactUsHero, contactUsSubverticals } from '../data/contact-us-data';
import Offices from '../components/Offices/Offices';
import CtaSecondary from '../components/Buttons/secondary-cta';

import heroBg from '../images/hero-images/contact-us-hero.png';
import knowledge from '../images/subvertl-images/knowledge.png';
import team from '../images/subvertl-images/team.png';

export default function ContactUs() {
  return (
    <Layout>
      <SEO title="SpotOn - Contact Us" image={`https://spoton.com/${heroBg}`} />
      <ContactUsHero
        sectionData={contactUsHero}
        heroBg={heroBg}
        subverticals={contactUsSubverticals}
      />
      <Row justify="center">
        <Col lg={12}>
          <div className="small-grid">
            <img className="small-grid-icon" src={knowledge} alt="" />
            <div>
              <h4>Knowledge Base</h4>
              <CtaSecondary ctaTitle="Support documentation" target="/" />
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="small-grid">
            <img className="small-grid-icon" src={team} alt="" />
            <div>
              <h4>Help Center</h4>
              <CtaSecondary ctaTitle="SpotOn help center" target="/" />
            </div>
          </div>
        </Col>
      </Row>
      <Offices />
    </Layout>
  );
}
