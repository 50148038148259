// eslint-disable-next-line import/prefer-default-export
export const currentOffices = [
  {
    location: "Chicago, IL",
    address: "500 W Madison St, Chicago, IL 60661",
    phone: "+18778144102",
    phoneText: "(877) 814-4102",
    icon: "visual-chicago.png",
  },
  // {
  //   location: 'Detroit, MI',
  //   address: '4000 Town Center Ste 400, Southfield, MI 48075',
  //   phone: '+18778144102',
  //   phoneText: '(877) 814-4102',
  //   icon: 'visual-detroit.png',
  // },
  {
    location: "Mexico City, Mexico",
    address:
      "Calle Río Tiber 53, Centro, Cuauhtémoc, 06500 Ciudad de México, CDMX",
    phone: "+13035471799",
    phoneText: "(303) 547-1799",
    icon: "visual-mexico.png",
  },
  {
    location: "Kraków, Poland",
    address: "Aleja 29 Listopada 20, 31-401 Kraków",
    phone: "+13035471799",
    phoneText: "(303) 547-1799",
    icon: "visual-poland.png",
  },
];
