import React from 'react';
import { viewports, colors } from '../../style-vars';

const { smOrSmaller, mdOrSmaller } = viewports;
const { black } = colors;

const OfficesStyles = () => (
  <style jsx global>{`
    .offices {
      position: relative;
      padding: 0 0 32px 0;
    }
    @media ${mdOrSmaller} {
      .offices {
        padding: 3%;
      }
    }
    .offices h2 {
      font-weight: 800 !important;
      font-size: 32px !important;
      line-height: 1.5 !important;
    }
    .offices__headline {
      margin-top: 24px !important;
      text-align: center;
    }
    .offices__headquarters {
      padding: 32px;
      border-radius: 8px;
    }
    .offices__headquarters-circle {
      position: relative;
      width: 96px;
      height: 96px;
      border-radius: 96px;
      background-color: #d1e1ff;
      margin-bottom: 32px;
    }
    .offices__headquarters-circle img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .offices__headquarters p {
      color: #748093;
      font-size: 20px;
      margin-bottom: 0;
    }
    .offices__other {
      background: #e8f0ff;
      padding: 48px;
      border-radius: 28px;
      margin-top: 24px;
    }
    @media ${smOrSmaller} {
      .offices__other {
        padding: 20px;
      }
    }
    .offices__other__offices {
      display: grid;
      grid-template-columns: 64px 1fr;
      align-content: center;
      justify-content: center;
      align-items: start;
      grid-gap: 16px;
      margin-bottom: 32px;
    }
    .offices__other__offices img {
      width: 64px !important;
      object-fit: scale-down;
    }
    .offices__other__offices h4.ant-typography {
      color: ${black} !important;
      margin-bottom: 8px;
    }
    .offices__other__offices h5.ant-typography {
      margin-top: 8px !important;
      font-weight: 400 !important;
      color: #748093;
    }
    .offices__other__offices a {
      font-size: 16px;
    }
    .offices h3 {
      color: ${black} !important;
      margin-bottom: 32px !important;
    }
  `}</style>
);

export default OfficesStyles;
