import React from "react";
import { Row, Col, Typography } from "antd";
import Image from "../ImageQuerys/OfficeImages";
import { currentOffices } from "../../data/offices-data";
import bridge from "../../images/svg/bridge.svg";
import OfficesStyles from "./OfficesStyles";

const { Title } = Typography;

const Offices = () => {
  return (
    <section className="offices">
      <Title className="offices__headline" level={2}>
        Our locations<span className="blue_dot">.</span>
      </Title>
      <Row gutter={[32, 32]} align="middle">
        <Col lg={12}>
          <Image imageName="CA.png" />
        </Col>
        <Col lg={12}>
          <div className="offices__headquarters">
            <div className="offices__headquarters-circle">
              <img src={bridge} alt="" />
            </div>
            <Title level={2}>
              Corporate Headquarters
              <br />
              San Francisco, CA
            </Title>
            <p>100 California St, 9th Floor, San Francisco, CA 94111</p>
            <p>
              <a href="tel:+18778144102">(877) 814-4102</a>
            </p>
          </div>
        </Col>
      </Row>
      <Row gutter={[64, 24]}>
        <Col lg={24}>
          <div className="offices__other">
            <Title level={3}>
              Additional offices<span className="blue_dot">.</span>
            </Title>
            <Row>
              {currentOffices.map((office, idx) => (
                <Col key={`offices__${idx.toString()}`} lg={8} sm={24} xs={24}>
                  <div className="offices__other__offices">
                    <Image imageName={office.icon} />
                    <div>
                      <Title level={4}>{office.location}</Title>
                      <Title level={5}>{office.address}</Title>
                      <a href={`tel:${office.phone}`}>{office.phoneText}</a>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>

      <OfficesStyles />
    </section>
  );
};

export default Offices;
